import React from 'react';
import cx from 'classnames';
import { Button } from '../../../components/Button';
import styles from '../style.module.css';

interface Props {
  handleRequestNextPage: () => void;
  loading: boolean;
  text: string;
}

export const BtnMoreCards = ({ handleRequestNextPage, loading, text }: Props) => {
  const styleButtonLoadMoreCars = cx('mb8-ns mb8-m mt-0  mt24-l mb8-l', styles.buttonLoadMoreCars);
  return (
    <div className="flex justify-center pt32 pt24-ns pt24-m pb48 pb48-ns pb48-m pb72-l">
      <Button
        onClick={handleRequestNextPage}
        classButton={styleButtonLoadMoreCars}
        loading={loading}
      >
        {text}
      </Button>
    </div>
  );
};
