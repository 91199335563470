import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { AutoscarPlayIcon } from '../components/AutoscarPlayAds/icon-autoscarplay';
import styles from '../components/AutoscarPlayAds/style.module.css';
import { Grid } from '../components/Grid';
import { GridCell } from '../components/GridCell';
import { Layout } from '../components/Layout';
import { LoaderCard } from '../components/Loaders';
import { BtnMoreCards } from '../modules/AsideSearchBarAndContent/components/BtnMoreCards';
import { Cards } from '../modules/AsideSearchBarAndContent/components/Cards';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';
import { Top } from '../modules/Menu/components/MenuAd';
import { axiosInstance } from '../services/axios-instance';

export const AdvertisementResponse = {
  getAdvertisementResponse: async (
    filters: any,
    page: number,
    searchAd = '',
    orderAd: any = null,
    takenAd = 0,
  ) => {
    const search = searchAd ? encodeURI(searchAd) : null;
    const order = orderAd ? orderAd : null;
    const filter = { ...filters };
    const take = takenAd !== 0 && takenAd !== 12 ? takenAd : null;
    delete filter.search;
    const res = await axiosInstance.get('/advertisement/', {
      params: { filter, page, search, order, take },
    });

    return res;
  },
};

const formatCardData = (data: any[]) => {
  return Array.isArray(data)
    ? data.map(item => ({
        id: item.id,
        city: item.city,
        description: item.description,
        mileage: item.mileage,
        model: {
          brandName: item.model?.brandName || '',
          fabricationYear: item.model?.fabricationYear || 0,
          id: item.model?.id || 0,
          modelYear: item.model?.modelYear || 0,
          motor: item.model?.motor || '',
          name: item.model?.name || '',
          price: item.model?.price || 0,
          status: item.model?.status || '',
          type: item.model?.type || '',
          version: item.model?.version || '',
        },
        photoUrl: item.photoURL || [],
        state: item.state,
        url: item.url || '',
        price: item.price || 0,
        user: item.user || {},
        name: item.name || '',
      }))
    : [];
};

const AdvertisementPage: React.FC = () => {
  const [shopAds, setShopAds] = useState<any[]>([]);
  const [paginate, setPaginate] = useState(0);
  const [thereIsMore, setThereIsMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  const fetchShopAds = async () => {
    setIsLoading(true);
    try {
      const { data } = await AdvertisementResponse.getAdvertisementResponse(
        { isInAutoscarPlay: { eq: true } },
        paginate,
      );
      const formattedData = formatCardData(data.data);
      setShopAds(prevAds => [...prevAds, ...formattedData]);
      setThereIsMore(data.data.length > 0);
      setOffers(prevOffers => [...prevOffers, ...data.data]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShopAds();
  }, [paginate]);

  const handlePaginate = () => {
    if (thereIsMore && !isLoading) {
      setPaginate(paginate + 1);
    }
  };

  const renderCards = () => (
    <Grid width={1} className="pb12 pb0-ns justify-center items-center">
      {map(
        offers,
        (offer: { id: string; mileage: number; description: string; price: number }, idx) => {
          const { id, description } = offer;
          return (
            <Cards
              key={`${id}--${description}-${idx}`}
              offer={offer}
              sidebarOpen={false}
              vehicleType={''}
            />
          );
        },
      )}
    </Grid>
  );

  return (
    <Layout>
      <Top />
      <Menu menuHasTwoColors={false} />
      <div className={styles.autoscarPlayIconContainer}>
        <AutoscarPlayIcon style={{}} />
      </div>
      <div className={styles.contentContainer}>
        <GridCell className="flex flex-column items-center pb8 pb24-m">
          {renderCards()}
          {!isLoading && thereIsMore && (
            <BtnMoreCards
              handleRequestNextPage={handlePaginate}
              loading={isLoading}
              text="Carregar mais anúncios"
            />
          )}
          {isLoading && <LoaderCard />}
        </GridCell>
      </div>
      <Footer />
    </Layout>
  );
};

export default AdvertisementPage;
