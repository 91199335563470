import React from 'react';
import get from 'lodash/get';
import { CardAd } from '../../../components/CardAd';
import { GridCell } from '../../../components/GridCell';
import { PARTS_NUMBER } from '../../../utils/productType';
import { urlFormatted } from '../../Home/utils/formattedUrl';
import styles from '../style.module.css';

interface Props {
  sidebarOpen: boolean;
  offer: any;
  vehicleType: string | number;
}

export const Cards = ({ offer, sidebarOpen, vehicleType }: Props) => {
  const hasOpenSidebar = sidebarOpen ? [0.5, 0.33, 0.33, 0.25] : [0.5, 0.33, 0.33, 0.25];

  const offerModel = get(offer, 'model', {});

  const { name, modelYear, fabricationYear, brandName, version } = offerModel;
  const { id, mileage, price, user } = offer;
  const namePart = offer.name;

  const carName = vehicleType !== PARTS_NUMBER ? `${brandName} ${name} ${version}` : namePart;

  const userNameAd = get(user, user.type === 1 ? 'name' : 'fantasyName', '') || '';
  const photoUrl = get(offer, 'photoUrl', []);
  offer.vehicleType = vehicleType;
  const newUrl = urlFormatted(offer);

  return (
    <GridCell
      key={id + name}
      className="pt0-ns pb12 pb16-ns pb24-m pb24-l pr12 pr24-m pr24-l"
      width={hasOpenSidebar}
    >
      <div className={styles.sizeCard}>
        <a className="db no-underline" href={newUrl} target="_blank" rel="noreferrer">
          <CardAd
            carName={carName}
            modelYear={modelYear}
            fabricationYear={fabricationYear}
            km={mileage}
            clientName={userNameAd}
            price={price}
            urlPictures={photoUrl}
            url={newUrl}
            vehicleType={vehicleType}
            isNew={offer.newPart}
          />
        </a>
      </div>
    </GridCell>
  );
};
